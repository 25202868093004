'use client';

import Txt, { TxtProps } from '@/components/ui/Txt';
import { USE_RECAPTCHA } from './useRecaptchaExecute';

export const RecaptchaPolicy = (props: TxtProps) => {
  return USE_RECAPTCHA ? (
    <Txt html {...props}>
      {`This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and
      <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of service</a>
      apply.`}
    </Txt>
  ) : null;
};
