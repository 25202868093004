'use client';

import MenuButtons from '@/components/Menu/Menu.Buttons';
import MenuDrawer from '@/components/Menu/Menu.Drawer';
import NavMenu from '@/components/NavMenu';
import { AppNavigationMenus } from '@/lib/getters/getNavigation';
import type { AppGlobal } from '@/lib/parsers/globals/globals';
import { useBreakpointMax } from '@/theme';
import Flex from '../ui/Flex';

export type MenuProps = {
  expertise: Pick<AppNavigationMenus, 'sectorNav' | 'serviceNav' | 'productNav'>;
  menus: Pick<AppNavigationMenus, 'primaryNav' | 'secondaryNav' | 'tertiaryNav'>;
  socials: AppGlobal<'social_GlobalSet'>;
};

const Menu = ({
  menus: { primaryNav, secondaryNav, tertiaryNav },
  expertise: { sectorNav, serviceNav, productNav },
  socials,
}: MenuProps) => {
  const md = useBreakpointMax('md');

  return (
    <>
      <Flex direction="row" justifyContent="between" alignItems="center" columnGap="2xl">
        {!md && (
          <NavMenu
            ListProps={{
              direction: 'row',
            }}
            LinkProps={{
              variant: 'text',
              size: 'large',
              underline: 'none',
              cx: { fontSize: 'medium' },
            }}
            useSpecialExpertise
            items={primaryNav?.items}
            expertise={{
              sectorNav,
              serviceNav,
              productNav,
            }}
          />
        )}
        <MenuButtons cx={{ fontSize: 'h5' }} />
      </Flex>
      <MenuDrawer
        expertise={{
          sectorNav,
          serviceNav,
          productNav,
        }}
        menus={{
          primaryNav,
          secondaryNav,
          tertiaryNav,
        }}
        socials={socials}
      />
    </>
  );
};

export default Menu;
