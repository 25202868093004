'use client';

import { usePathname } from 'next/navigation';
import React from 'react';

const SiteImprovePageTracking = () => {
  const pathname = usePathname();
  const [previousPathname, setPreviousPathname] = React.useState('');

  React.useEffect(() => {
    if (!previousPathname) {
      setPreviousPathname(pathname);
      return;
    }

    if (pathname === previousPathname) return;

    const ref = new URL(previousPathname, window.location.origin).toString();
    const url = new URL(pathname, window.location.origin).toString();
    const title = document.title;

    const payload = {
      url,
      ref,
      title,
    };

    // devLog('SiteImprovePageTracking', payload);

    try {
      if (!('_sz' in window)) return;

      (window._sz as unknown[]).push(['trackdynamic', payload]);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
};

export default SiteImprovePageTracking;
