'use client';

import { useMenu } from '@/components/Menu/useMenu';
import { NavMenuItem } from '@/lib/parsers/navigation';
import { Sprinkles } from '@/theme/sprinkles.css';
import Btn from '../ui/Btn';

export const MenuLink = ({ item, cx }: { item: NavMenuItem; cx?: Sprinkles }) => {
  const { toggle: toggleMenu } = useMenu();

  return (
    <Btn
      variant="text"
      onClick={() => toggleMenu(false)}
      cx={cx}
      {...{ href: item.href ?? '', item }}>
      {item.title}
    </Btn>
  );
};

export default MenuLink;
