'use client';

import Logo from '@/components/Logo';
import NavbarSearch from '@/components/NavBar/Navbar.Search';
import Flex from '@/components/ui/Flex';
import { roundToTwo } from '@/lib/utils/format';
import useScrollPosition from '@/lib/utils/useScrollPosition';
import { setElementVars } from '@vanilla-extract/dynamic';
import { useTranslations } from 'next-intl';
import React, { useEffect, useRef } from 'react';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';
import HideOnScroll from '../HideOnScroll';
import Box from '../ui/Box';
import * as styles from './Navbar.css';
import { useNavbarState } from './useNavbarState';

type Size = {
  height?: number;
};

type NavBarProps = React.PropsWithChildren;

const NavBar = ({ children }: NavBarProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { setHeight: setNavbarHeight, height: navbarHeight } = useNavbarState();
  useTranslations;
  const pos = useScrollPosition();

  const onResize = useDebounceCallback((size: Size) => {
    size.height && setNavbarHeight(size.height);
  }, 200);

  useResizeObserver({
    ref,
    onResize,
    box: 'border-box',
  });

  useEffect(() => {
    const body = document.body;
    if (!navbarHeight) return;

    setElementVars(body, {
      [styles.navBarVars.height]: `${roundToTwo(navbarHeight ?? 0)}px`,
    });
  }, [navbarHeight]);

  return (
    <Box>
      <HideOnScroll>
        <Box className={styles.inner}>
          <Box ref={ref} className={styles.navbar({ withViewColorTheme: pos > 0 })}>
            <Flex direction="row" justifyContent="between" alignItems="center">
              <Logo homeLink />
              {children}
            </Flex>
          </Box>
        </Box>
      </HideOnScroll>
      <NavbarSearch />
    </Box>
  );
};

export default NavBar;
