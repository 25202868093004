import Box, { BoxProps } from '@/components/ui/Box';
import Btn, { BtnBaseProps, BtnProps } from '@/components/ui/Btn';
import Flex, { FlexProps } from '@/components/ui/Flex';
import { ENTRY_TYPE_URI_SETTINGS } from '@/lib/constants';
import { AppNavigationMenus } from '@/lib/getters/getNavigation';
import { NavMenuItem as ParsedNavMenuItem } from '@/lib/parsers/navigation';
import { vars } from '@/theme/vars.css';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import ExpertiseMenuDrawer from '../Menu/ExpertiseMenu.Drawer';
import * as style from './NavMenu.css';

export type NavMenuListProps = FlexProps<
  'ul',
  {
    items?: ParsedNavMenuItem[];
    LinkProps?: BtnProps;
    ListItemProps?: BoxProps;
    maxDepth?: number;
    divider?: boolean;
    useSpecialExpertise?: boolean;
    expertise?: Pick<AppNavigationMenus, 'sectorNav' | 'serviceNav' | 'productNav'>;
  }
>;

type NavMenuItemProps = {
  links: ParsedNavMenuItem[];
  link: ParsedNavMenuItem;
  LinkProps?: BtnProps;
  ListItemProps?: BoxProps;
  useSpecialExpertise?: boolean;
  expertise?: Pick<AppNavigationMenus, 'sectorNav' | 'serviceNav' | 'productNav'>;
  divider: boolean;
  index: number;
};

const NavMenuItem = ({
  links,
  link,
  LinkProps,
  ListItemProps,
  divider,
  useSpecialExpertise,
  expertise,
  index,
}: NavMenuItemProps) => {
  const isExpertiseLink = link.href === `/${ENTRY_TYPE_URI_SETTINGS.areaIndex}`;
  const useExpertise = useSpecialExpertise && isExpertiseLink;

  return !useExpertise ? (
    <Box as="li" key={link.id} className={style.listItem} {...ListItemProps}>
      {link.href ? (
        <Btn
          variant="unstyled"
          href={link.href}
          as="a"
          style={
            divider && index !== links.length - 1
              ? {
                  borderRight: `1px solid ${vars.palette.colors.accent}`,
                  paddingRight: vars.spacing['3xs'],
                }
              : {}
          }
          {...LinkProps}>
          {link.title}
        </Btn>
      ) : (
        <Btn {...LinkProps}>{link.title}</Btn>
      )}
    </Box>
  ) : (
    expertise && <ExpertiseMenuDrawer item={link} menus={expertise} />
  );
};

const NavMenuList = ({
  items,
  // maxDepth,
  LinkProps,
  ListItemProps,
  divider = false,
  useSpecialExpertise,
  expertise,
  ...props
}: NavMenuListProps) => {
  if (!items) return null;

  return (
    <Flex as="ul" direction="column" spacing="sm" {...mergePropsClassName(props, style.list())}>
      {items?.map((link, idx) => (
        <NavMenuItem
          key={link.id}
          index={idx}
          links={items}
          link={link}
          divider={divider}
          LinkProps={LinkProps}
          ListItemProps={ListItemProps}
          useSpecialExpertise={useSpecialExpertise}
          expertise={expertise}
        />
      ))}
    </Flex>
  );
};

export type NavMenuProps<C extends React.ElementType = 'nav', P = NoProps> = FlexProps<
  C,
  {
    BtnProps?: BtnBaseProps;
    ListProps?: NavMenuListProps;
    LinkProps?: BtnProps;
    items?: ParsedNavMenuItem[];
    divider?: boolean;
    useSpecialExpertise?: boolean;
    expertise?: Pick<AppNavigationMenus, 'sectorNav' | 'serviceNav' | 'productNav'>;
  } & P
>;

const NavMenu = <C extends React.ElementType = 'nav'>({
  items,
  ListProps,
  LinkProps,
  divider,
  useSpecialExpertise,
  expertise,
  ...props
}: NavMenuProps<C>) => {
  if (!items) return null;

  return (
    <Flex spacing="sm" {...mergePropsClassName(props, style.root())}>
      <NavMenuList
        items={items}
        {...ListProps}
        LinkProps={LinkProps}
        divider={divider}
        useSpecialExpertise={useSpecialExpertise}
        expertise={expertise}
      />
    </Flex>
  );
};

export default NavMenu;
