'use client';

import { useBreakpoints } from '@/theme';
import Icon from '../Icon';
import Btn from '../ui/Btn';
import Grid, { GridColProps } from '../ui/Grid';

type FooterTopProps = {
  overrideVisible?: boolean;
  GridColProps?: GridColProps;
};

const FooterTop = ({ overrideVisible, GridColProps }: FooterTopProps) => {
  const breakpoint = useBreakpoints();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const show = overrideVisible ? true : breakpoint?.md;

  return (
    show && (
      <Grid.Col md={1} {...GridColProps}>
        <Btn onClick={handleScrollToTop} variant="text" color="accent" style={{ fontSize: '24px' }}>
          <Icon name="arrowUp" color="inherit" />
        </Btn>
      </Grid.Col>
    )
  );
};

export default FooterTop;
