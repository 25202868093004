'use client';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_KEY, RecaptchaProps } from './useRecaptchaExecute';

export default function RecaptchaProvider({ children }: RecaptchaProps) {
  return RECAPTCHA_KEY ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_KEY}
      // useEnterprise
      scriptProps={{
        async: false, // optional, default to false,
        defer: true, // optional, default to false
        appendTo: 'body', // optional, default to "head", can be "head" or "body",
        nonce: undefined,
      }}
      container={{
        parameters: {
          badge: 'inline',
        },
      }}>
      {children}
    </GoogleReCaptchaProvider>
  ) : (
    <>{children}</>
  );
}
