'use client';

import IconButton from '@/components/IconButton';
import { useMenu, useSearchMenu } from './useMenu';
import Flex, { FlexProps } from '../ui/Flex';

export type MenuButtonsProps = FlexProps<
  'div',
  { search?: boolean; menu?: boolean; close?: boolean }
>;

const MenuButtons = ({ search = true, menu = true, close = false, ...props }: MenuButtonsProps) => {
  const { toggle: toggleMenu } = useMenu();
  const { toggle: toggleSearchMenu } = useSearchMenu();

  return (
    <Flex direction="row" columnGap="2xs" {...props}>
      {search && <IconButton variant="nav" icon="search" onClick={() => toggleSearchMenu()} />}
      {menu && <IconButton variant="nav" icon="menu" onClick={() => toggleMenu()} />}
      {close && <IconButton variant="nav" icon="close" onClick={() => toggleMenu()} />}
    </Flex>
  );
};

export default MenuButtons;
