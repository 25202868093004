import EntryIndexProvider from '@/components/EntryIndex/EntryIndex.Provider';
import SearchBarInput from '@/components/SearchBar/SearchBar.Input';
import SearchBarResults from '@/components/SearchBar/SearchBar.Results';
import { SEARCHABLE_ENTRY_TYPES } from '@/lib/constants';
import { Box } from '@mui/material';
import { Suspense } from 'react';
import * as styles from './SearchBar.css';

export type SearchBarProps = {
  /**/
};

const SearchBar = (_props: SearchBarProps) => {
  return (
    <Suspense>
      <EntryIndexProvider
        perPage={5}
        baseEntryQuery={{
          section: SEARCHABLE_ENTRY_TYPES,
        }}
        filtersConfig={[]}
        autoSubmit={false}
        skipInitialQuery>
        <Box className={styles.root}>
          <Box className={styles.inner}>
            <SearchBarInput />
            <SearchBarResults />
          </Box>
        </Box>
      </EntryIndexProvider>
    </Suspense>
  );
};

export default SearchBar;
