'use client';

import CtaButton from '@/components/CtaButton';
import { SEARCH_PARAM } from '@/components/EntryIndex/entryIndexConstants';
import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import { useSearchMenu } from '@/components/Menu/useMenu';
import SearchBarResult from '@/components/SearchBar/SearchBar.Result';
import Box from '@/components/ui/Box';
import Divider from '@/components/ui/Divider';
import Txt from '@/components/ui/Txt';
import { useNavigationEvents } from '@/lib/utils/useNavigationEvents';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import * as styles from './SearchBar.css';
import { MIN_SEARCH_LENGTH } from './SearchBar.Input';

const SearchBarResults = () => {
  const router = useRouter();
  const { toggle: toggleSearchMenu } = useSearchMenu();
  useNavigationEvents({ onNavigate: () => toggleSearchMenu(false) });

  const t = useTranslations('search');

  const loading = useEntryIndex((s) => s.loading);
  const totalItems = useEntryIndex((s) => s.totalItems);
  const items = useEntryIndex((s) => s.items);
  const search = useEntryIndex((s) => s.search);
  const hasSearch = useEntryIndex((s) => s.search && s.search.length >= MIN_SEARCH_LENGTH);
  const hasResults = items.length > 0;
  const showLoading = loading && hasSearch;

  const handleAllClick = () => {
    toggleSearchMenu(false);
    const viewAllUrl = `/search?${SEARCH_PARAM}=${search}`;
    router.push(viewAllUrl, {});
  };

  return hasSearch ? (
    <Box className={styles.resultsWrap}>
      {hasResults && items.map((item) => <SearchBarResult key={item.id} item={item} />)}
      <Divider className={styles.divider} />
      {showLoading ? (
        <Txt>{t('loading', { query: search })}</Txt>
      ) : hasResults ? (
        <Box>
          <CtaButton className={styles.viewAllButton} onClick={handleAllClick}>
            {t('viewAll', { count: totalItems })}
          </CtaButton>
        </Box>
      ) : (
        <Txt>{t('noResults', { query: search })}</Txt>
      )}
    </Box>
  ) : null;
};

export default SearchBarResults;
