'use client';

import { useBreakpointMax } from '@/theme';
import Logo from '../Logo';
import Grid from '../ui/Grid';
import FooterTop from './Footer.Top';
import Flex from '../ui/Flex';

const FooterTopBar = () => {
  const md = useBreakpointMax('md');

  return (
    <Grid.Col md={4}>
      <Flex direction="row">
        <Logo variant="mono" homeLink />
        {md && (
          <FooterTop
            overrideVisible={true}
            GridColProps={{ cx: { display: 'flex', justifyContent: 'end' } }}
          />
        )}
      </Flex>
    </Grid.Col>
  );
};

export default FooterTopBar;
