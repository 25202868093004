'use client';

import { MenuProps } from '@/components/Menu';
import { useMenu } from '@/components/Menu/useMenu';
import NavMenu from '@/components/NavMenu';
import Box from '@/components/ui/Box';
import { ENTRY_TYPE_URI_SETTINGS } from '@/lib/constants';
import { NavMenuItem } from '@/lib/parsers/navigation';
import { useNavigationEvents } from '@/lib/utils/useNavigationEvents';
import { useBreakpointMax } from '@/theme';
import { Drawer } from '@mui/material';
import Socials from '../Socials';
import Flex from '../ui/Flex';
import UnstyledAccordion from '../UnstyledAccordion';
import ExpertiseMenuAccordion from './ExpertiseMenu.Accordion';
import MenuButtons from './Menu.Buttons';
import * as style from './Menu.css';
import MenuLink from './Menu.Link';

type MenuDrawerProps = MenuProps;

const MenuDrawer = ({ menus, expertise, socials }: MenuDrawerProps) => {
  const { value: menuOpen, toggle: toggleMenu } = useMenu();
  useNavigationEvents({ onNavigate: () => toggleMenu(false) });

  const md = useBreakpointMax('md');

  // Find expertise menu item from primary nav if it exists
  const expertiseItem = menus?.primaryNav?.items?.find(
    (item) => item.href === `/${ENTRY_TYPE_URI_SETTINGS.areaIndex}`
  ) as NavMenuItem;

  // Combine expertise with secondary items for mobile
  const secondaryItems = md
    ? [expertiseItem, ...(menus?.secondaryNav?.items ?? [])]
    : menus.secondaryNav?.items;

  // Hide expertise from primary items on mobile
  const mobileItems = menus?.primaryNav?.items?.filter(
    (item) => item.href !== `/${ENTRY_TYPE_URI_SETTINGS.areaIndex}`
  );

  return (
    <Drawer anchor="right" open={menuOpen} onClose={() => toggleMenu(false)}>
      <Box className={style.drawer}>
        <MenuButtons menu={false} close justifyContent="end" cx={{ fontSize: 'h4' }} />
        <Flex rowGap="xl" className={style.drawerInner}>
          <NavMenu
            LinkProps={{
              variant: 'text',
              underline: 'none',
              fontFamily: 'heading',
              cx: { fontSize: 'h3' },
              onClick: () => toggleMenu(false),
            }}
            items={!md ? menus?.primaryNav?.items : mobileItems}
          />
          {menus.secondaryNav?.items && (
            <UnstyledAccordion
              items={
                secondaryItems?.map((item, index) => {
                  return {
                    LabelProps: {
                      children: <MenuLink item={item} />,
                    },
                    CaretProps: {
                      openIconProps: { cx: { color: 'accent' } },
                    },
                    BodyProps: {
                      index,
                      nestedChildren:
                        item.href === `/${ENTRY_TYPE_URI_SETTINGS.areaIndex}` ? (
                          <ExpertiseMenuAccordion menus={expertise} />
                        ) : undefined,
                      children:
                        item.href !== `/${ENTRY_TYPE_URI_SETTINGS.areaIndex}`
                          ? item?.subItems?.map((subItem) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              const { subItems, href, id, ...rest } = subItem;

                              return <MenuLink key={id} item={subItem} />;
                            }) ?? []
                          : undefined,
                    },
                    index,
                  };
                }) ?? []
              }
            />
          )}
          <NavMenu
            ListProps={{
              direction: 'row',
              wrap: true,
              rowGap: 'none',
              ListItemProps: { style: { flexBasis: '35%' } },
            }}
            LinkProps={{
              variant: 'text',
              underline: 'hover',
              color: 'neutral',
              cx: { fontSize: 'small' },
            }}
            items={menus?.tertiaryNav?.items}
          />

          <Flex direction="row" wrap="wrap" columnGap="2xs" rowGap="2xs">
            <Socials BtnProps={{ cx: { fontSize: 'h5' } }} links={socials.socialLinks} />
          </Flex>
        </Flex>
      </Box>
    </Drawer>
  );
};

export default MenuDrawer;
