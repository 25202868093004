'use client';

import { Slide, useScrollTrigger } from '@mui/material';

export type HideOnScrollProps = {
  children: React.ReactElement;
  in?: boolean;
};

const HideOnScroll = ({ in: customIn, children }: HideOnScrollProps) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={customIn ?? !trigger}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
