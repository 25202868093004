'use client';

import { usePathname } from 'next/navigation';
import React, { useEffect } from 'react';

export type NavigationEventsProps = {
  onNavigate?: (uri: string) => void;
};

export function useNavigationEvents({ onNavigate }: NavigationEventsProps) {
  const pathname = usePathname();
  const searchParams = null; // useSearchParams();
  const [currentUri, setCurrentUri] = React.useState<string>();

  useEffect(() => {
    const uri = [pathname, searchParams].filter(Boolean).join('?');

    if (!currentUri) {
      setCurrentUri(uri);
      return;
    }

    if (uri !== currentUri) {
      onNavigate && onNavigate(uri);
      setCurrentUri(uri);
    }
  }, [currentUri, pathname, onNavigate, searchParams]);

  return {
    currentUri,
  };
}
