'use client';

import { useExpertiseMenu } from '@/components/Menu/useMenu';
import NavMenu from '@/components/NavMenu';
import { AppNavigationMenus } from '@/lib/getters/getNavigation';
import { NavMenuItem } from '@/lib/parsers/navigation';
import { vars } from '@/theme/vars.css';
import { Drawer } from '@mui/material';
import { useTranslations } from 'next-intl';
import CtaButton from '../CtaButton';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './Menu.css';

type ExpertiseMenuDrawerProps = {
  item: NavMenuItem;
  menus: Pick<AppNavigationMenus, 'sectorNav' | 'serviceNav' | 'productNav'>;
};

const ExpertiseMenuDrawer = ({ menus, item }: ExpertiseMenuDrawerProps) => {
  const t = useTranslations();
  const { value: menuOpen, toggle: toggleMenu } = useExpertiseMenu();

  const MenuLink = (item: NavMenuItem) => {
    return (
      <Btn
        variant="text"
        size="large"
        underline="none"
        cx={{ fontSize: 'medium' }}
        onClick={() => toggleMenu(!menuOpen)}
        endIcon={menuOpen ? 'chevronUp' : 'chevronDown'}>
        {item.title}
      </Btn>
    );
  };

  const MenuList = ({ items, menuTitle }: { items?: NavMenuItem[]; menuTitle?: string }) => {
    return (
      items && (
        <Flex cx={{ width: 'full' }}>
          {menuTitle && <Txt variant="h5">{menuTitle}</Txt>}
          <NavMenu
            ListProps={{ spacing: '2xs' }}
            LinkProps={{
              variant: 'text',
              underline: 'none',
              wrap: true,
              cx: { fontSize: 'medium' },
              onClick: () => toggleMenu(false),
            }}
            items={items}
          />
          <Box cx={{ mT: 'sm' }}>
            <CtaButton
              className={styles.expertiseViewAllButton}
              href="/expertise"
              onClick={() => toggleMenu(false)}
              cx={{ fontSize: 'medium' }}
              underline="always"
              EndIconProps={{ name: 'arrowRight', color: 'inherit' }}>
              {t('actions.viewAll')}
            </CtaButton>
          </Box>
        </Flex>
      )
    );
  };

  return (
    <>
      <MenuLink {...item} />
      <Drawer
        anchor="top"
        open={menuOpen}
        onClose={() => toggleMenu(false)}
        sx={{ zIndex: vars.zIndex.drawer }}
        PaperProps={{ sx: { zIndex: vars.zIndex.drawer }, elevation: 0 }}>
        <Box className={styles.expertiseDrawer}>
          <Flex cx={{ pT: 'lg', pX: '4xl', pB: '4xl' }} columnGap="xl" direction="row">
            <MenuList items={menus?.sectorNav?.items} menuTitle={t('common.sectors')} />
            <MenuList items={menus?.serviceNav?.items} menuTitle={t('common.services')} />
            <MenuList items={menus?.productNav?.items} menuTitle={t('common.products')} />
          </Flex>
        </Box>
      </Drawer>
    </>
  );
};

export default ExpertiseMenuDrawer;
