'use client';

import { theme, themeDark } from '@/theme/mui/muiTheme';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import React, { PropsWithChildren } from 'react';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

export type ThemeProviderProps = PropsWithChildren<{
  mode?: 'light' | 'dark';
}>;

const defaultMode = 'light'; // darkMode ? 'dark' : 'light';

const ThemeProvider = ({ children, mode = defaultMode }: ThemeProviderProps) => {
  const darkMode = mode === 'dark';
  return (
    <MuiThemeProvider theme={darkMode ? themeDark : theme}>
      <React.Fragment>{children}</React.Fragment>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
