import { disableDraftMode, getDraftModeEnabled } from '@/lib/draftMode/draftModeActions';
import { getPreviewShareUrl } from '@/lib/draftMode/previewShareUrl';

import React, { useCallback, useState } from 'react';

/**
 * useDraftMode is a custom React hook that provides functionality related to draft mode.
 * It uses a custom usePathname hook to get the current path.
 * It also uses the getDraftModeEnabled function to check if draft mode is currently enabled.
 *
 * @returns {Object} - An object with two properties:
 * 1. enabled: A boolean indicating whether draft mode is currently enabled. This value is fetched asynchronously when the component mounts and whenever the pathname changes.
 * 2. exit: A function that, when called, disables draft mode and reloads the page.
 */
export const useDraftMode = () => {
  // Use a custom usePathname hook to get the current path

  // Initialize the enabled state to false
  const [enabled, setEnabled] = useState(false);

  const [previewShareUrl, setPreviewShareUrl] = useState<string>();

  const checkEnabled = React.useCallback(async () => {
    // Fetch the current draft mode status whenever the pathname changes
    return await getDraftModeEnabled().then((enabled) => {
      setEnabled(enabled);

      if (!enabled) {
        setPreviewShareUrl(undefined);
      } else {
        getPreviewShareUrl().then(setPreviewShareUrl);
      }

      return enabled;
    });
  }, []);

  // Define the exit function, which disables draft mode and reloads the page
  const exit = useCallback(() => {
    disableDraftMode().then(() => window.location.reload());
  }, []);

  // Return an object with the enabled property and the exit function
  return {
    checkEnabled,
    enabled,
    previewShareUrl,
    exit,
  };
};
