import { AppNavigationMenus } from '@/lib/getters/getNavigation';
import { useTranslations } from 'next-intl';
import CtaButton from '../CtaButton';
import UnstyledAccordion from '../UnstyledAccordion';
import Btn from '../ui/Btn';
import MenuLink from './Menu.Link';
import * as styles from './Menu.css';
import { useMenu } from './useMenu';

type ExpertiseMenuAccordionProps = {
  menus: Pick<AppNavigationMenus, 'sectorNav' | 'serviceNav' | 'productNav'>;
};

export const ExpertiseMenuAccordion = ({ menus }: ExpertiseMenuAccordionProps) => {
  const { toggle: toggleMenu } = useMenu();
  const t = useTranslations('actions');
  const { sectorNav, serviceNav, productNav } = menus;

  // Make top level from sectorNav, serviceNav, productNav
  const items = [sectorNav, serviceNav, productNav];

  return (
    <UnstyledAccordion BoxProps={{ cx: { width: 'full' } }}>
      {items.map((item, index) => {
        return (
          <UnstyledAccordion.Item
            key={index}
            {...{
              index,
              style: { border: 'none' },
              HandleProps: { style: { paddingTop: 0 } },
              LabelProps: { children: <Btn variant="text">{item?.name}</Btn> },
              CaretProps: {
                openIconProps: { cx: { color: 'accent' } },
              },
              BodyProps: {
                index,
                children: (
                  <>
                    {item?.items?.map((subItem) => {
                      return (
                        <MenuLink key={subItem.id} item={subItem} cx={{ fontSize: 'small' }} />
                      );
                    })}
                    <CtaButton
                      className={styles.expertiseViewAllButton}
                      href="/expertise"
                      onClick={() => toggleMenu(false)}
                      cx={{ fontSize: 'small' }}
                      underline="always"
                      EndIconProps={{ name: 'arrowRight', color: 'inherit' }}>
                      {t('viewAll')}
                    </CtaButton>
                  </>
                ),
              },
            }}
          />
        );
      })}
    </UnstyledAccordion>
  );
};

export default ExpertiseMenuAccordion;
