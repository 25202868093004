'use client';

import {
  DRAFT_MODE_SHARE_TOKEN_PARAM_NAME,
  DRAFT_MODE_TOKEN_PARAM_NAME,
} from '@/lib/draftMode/draftModeConstants';
import { useDraftMode } from './useDraftMode';

import CopyIcon from '@mui/icons-material/CopyAll';
import ExitIcon from '@mui/icons-material/ExitToApp';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  OutlinedInput,
  Paper,
  Snackbar,
} from '@mui/material';
import { useSearchParams } from 'next/navigation';
import React from 'react';

const FORCE_DRAFT_MODE_EXIT = false;

const PreviewToolbarClient = () => {
  const searchParams = useSearchParams();
  const { checkEnabled, enabled, exit, previewShareUrl } = useDraftMode();

  const [open, setOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const isLivePreview = previewShareUrl?.includes('x-craft-live-preview');

  React.useEffect(() => {
    const draftToken = searchParams.get(DRAFT_MODE_TOKEN_PARAM_NAME);
    const shareDraftToken = searchParams.get(DRAFT_MODE_SHARE_TOKEN_PARAM_NAME);
    const hasToken = draftToken || shareDraftToken;

    if (!FORCE_DRAFT_MODE_EXIT) return;

    checkEnabled().then((enabled) => {
      if (enabled && !hasToken) exit();
    });
  }, [checkEnabled, exit, searchParams]);

  // if (!isCurrentPage) return null;
  if (!enabled) return null;

  const handleCopyPreviewUrl = () => {
    if (!previewShareUrl || isLivePreview) return;
    try {
      navigator.clipboard.writeText(previewShareUrl);

      setCopied(true);
    } catch (error) {
      console.error('Error copying preview URL to clipboard', error);
    }
  };

  return (
    <>
      <Paper
        // onMouseLeave={() => setOpen(false)}
        elevation={4}
        sx={{
          position: 'fixed',
          bottom: 2,
          left: 2,
          zIndex: 200,
          display: 'flex',
          flexDirection: 'row',
          bgcolor: 'white',
          borderRadius: 1,
        }}>
        <IconButton color="primary" onClick={() => setOpen(!open)}>
          <EyeIcon />
        </IconButton>
        <Menu open={open} onClose={() => setOpen(false)}>
          <ListItemButton onClick={exit}>
            <ListItemIcon>
              <ExitIcon />
            </ListItemIcon>
            <ListItemText>{'Exit preview'}</ListItemText>
          </ListItemButton>

          {previewShareUrl && (
            <ListItem>
              <OutlinedInput
                readOnly
                value={previewShareUrl}
                startAdornment={
                  !isLivePreview ? (
                    <CopyIcon
                      sx={{
                        cursor: 'pointer',
                        marginRight: 1,
                      }}
                      onClick={handleCopyPreviewUrl}
                    />
                  ) : undefined
                }
                sx={{
                  minWidth: 400,
                }}
              />
            </ListItem>
          )}
        </Menu>
      </Paper>
      <Snackbar
        open={copied}
        message={'Link Copied'}
        onClose={() => setCopied(false)}
        autoHideDuration={3000}
      />
    </>
    // <ClickAwayListener onClickAway={() => setOpen(false)}>
    // </ClickAwayListener>
  );
};

export default PreviewToolbarClient;
