import { colorValues } from '@/theme/partials/palette.css';
import { PaletteMode, ThemeOptions } from '@mui/material';

export const getThemePalette = (mode: PaletteMode): ThemeOptions['palette'] => {
  const isDark = mode === 'dark';

  return {
    mode,
    primary: {
      main: colorValues.black,
      contrastText: colorValues.white,
    },
    divider: isDark ? colorValues.white : colorValues.black,
    background: {
      ...(isDark
        ? {
            paper: colorValues.black,
          }
        : {
            paper: colorValues.white,
          }),
    },
  };
};
