'use client';

import IconButton from '@/components/IconButton';
import Logo from '@/components/Logo';
import { useSearchMenu } from '@/components/Menu/useMenu';
import SearchBar from '@/components/SearchBar';
import Container from '@/components/ui/Container';
import Flex from '@/components/ui/Flex';
import Txt from '@/components/ui/Txt';
import { useNavigationEvents } from '@/lib/utils/useNavigationEvents';
import { Drawer } from '@mui/material';
import { useTranslations } from 'next-intl';
import * as styles from './Navbar.css';

const NavbarSearch = () => {
  const { value: open, toggle } = useSearchMenu();

  useNavigationEvents({ onNavigate: () => toggle(false) });

  const t = useTranslations('search');

  return (
    <Drawer anchor="right" open={open} onClose={() => toggle(false)}>
      <Flex className={styles.searchModalHeader} direction="row">
        <Logo className={styles.searchModalLogo} onClick={() => toggle(false)} />
        <IconButton
          icon="close"
          variant="nav"
          size="large"
          onClick={() => toggle(false)}
          cx={{ fontSize: 'h5' }}
        />
      </Flex>
      <Flex className={styles.searchModalBody}>
        <Container maxWidth="md">
          <Txt variant="h3">{t('title')}</Txt>
          <SearchBar />
        </Container>
      </Flex>
    </Drawer>
  );
};

export default NavbarSearch;
