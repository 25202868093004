'use client';

import { Link } from '@/lib/parsers/common';
import { useTranslations } from 'next-intl';
import Btn from '../ui/Btn';
import Grid from '../ui/Grid';

export type FooterSubscribeProps = {
  link?: Link | null;
};

const FooterSubscribe = ({ link }: FooterSubscribeProps) => {
  const t = useTranslations('common');
  return (
    <Grid.Col md={2}>
      {link && (
        <Btn color="white" underline="hover" variant="text" {...link}>
          {link.children || t('subscribe')}
        </Btn>
      )}
    </Grid.Col>
  );
};

export default FooterSubscribe;
